<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
VueCookies.config("", "", "", true);
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  height: 100vh;
}
</style>
