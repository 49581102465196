import VueCookies from "vue-cookies";
import router from "@/routes";
import { logoutFromSaas } from "./services";
import axios from "@/axios";

export const AuthStore = {
    state: {
        user: null,
        userToken: null,
        userLoggedIn: false,
        client_id: null,
        client_name: null,
        is_client_workflow_admin: false,
        tenant_id:null,
        login_screen_info: null,
    },

    mutations: {
        SET_TENANT_ID(state,payload) {
            state.tenant_id = payload;
        },
        SET_USER(state, payload) {
            state.user = payload;
        },
        SET_LOGIN_STATE(state, payload) {
            state.userLoggedIn = payload;
        },
        SET_USER_TOKEN(state, payload) {
            state.userToken = payload;
            VueCookies.set("token", payload.access_token);
        },
        SET_CLIENT_ID(state, payload) {
            state.client_id = payload;
        },
        SET_CLIENT_NAME(state, payload) {
            state.client_name = payload;
        },
        SET_IS_CLIENT_WORKFLOW_ADMIN(state, payload) {
            state.is_client_workflow_admin = payload;
        },
        SET_LOGIN_SCREEN_INFO(state, { data }) {
            state.login_screen_info = data;
        }
    },

    getters: {
        getUser: (state) => state.user,
        userToken: (state) => state.userToken,
        userLoggedIn: (state) => state.userLoggedIn,
        getClientId: (state) => state.client_id,
        getClientName: (state) => state.client_name,
        getIsClientWorkFlowAdmin: (state) => state.is_client_workflow_admin,
        getTenantId: (state) => state.tenant_id,
        getLoginScreenInfo: (state) => state.login_screen_info,
    },

    actions: {
        // [FETCH_USER_BY_ID]: ({ commit }, { id }) => {

        //   commit(SET_USER, { user: null });

        // }
        // setUserAdminStatus({commit}, payload) {
        //     commit('SET_USER_ADMIN_STATUS', payload);
        // },

        async fetchClientId({ commit }) {
            try {
              const { data } = await axios.post(`client-auth/init`, {});
              if (data) {
                // to be changed
                commit("SET_CLIENT_ID", data?.client_id);
                commit("SET_CLIENT_NAME", data?.client_details?.name);
                commit("SET_USER", data?.user_details)
                commit('SET_IS_CLIENT_WORKFLOW_ADMIN', data?.is_client_workflow_admin);
                commit("SET_TENANT_ID", data?.tenant_id);
              }
            } catch (error) {
              console.log(error.message || `ERROR CLIENT TENANT ID`);
            }
        },
        setClientId: ({ commit }, payload) => {
            // localStorage.setItem("client_id", payload)
            VueCookies.set("client_id", payload.token);
            commit("SET_CLIENT_ID", payload);
        },
        setLoginSession({ commit }, payload) {
            commit("SET_USER", payload.user);
            commit("SET_LOGIN_STATE", payload.userLoggedIn);
            VueCookies.set("token", payload.token);
            VueCookies.set("refresh_token", payload.refresh_token);
            commit("SET_USER_TOKEN", { access_token: payload.token });
            // window.open('/', "_self")
        },
        setNewUserToken({ commit }, payload) {
            VueCookies.set("token", payload.access_token);
            VueCookies.set("refresh_token", payload.refresh_token);
            commit("SET_USER_TOKEN", { access_token: payload.access_token });
        },

        async logoutUser() {
            // console.log(!VueCookies.get("next-url").includes("#fileManager"))
             let getLogoutUri;

            try {
                getLogoutUri = await logoutFromSaas();
            } catch (error) {
                if (error === "LogoutFailed") {
                    console.log("Logout!");
                }
            } finally {
                VueCookies.remove("token");
                VueCookies.remove("client_id");
                VueCookies.remove("refresh_token");
                // // VueCookies.remove("ACCOUNT_ID")
                 const loginPath = router.resolve({ name: "Login" });
                if(!VueCookies.get("next-url").includes("#fileManager")){
                    VueCookies.set("next-url", loginPath.href);}
                window.location.href = getLogoutUri;
            }
        },
    },
};
